@tailwind base;
@tailwind components;
@tailwind utilities;
@import "rsuite/dist/rsuite.css";

body {
  font-family: "Inter", sans-serif;
}

/* Typography */
.text-title {
  font-size: 38px;
  font-weight: 300;
  line-height: 54px;
  letter-spacing: -1.9px;
}

.text-subtitle {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -1.4px;
}

.text-heading1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.6px;
}

.text-heading2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.16px;
}

.text-heading3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.text-body {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

.text-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}

.text-footnote {
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.text-detail {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* Elevation */
.elevation-1 {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.05);
}

.elevation-2 {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.05), 0px 8px 16px -4px
    rgba(0, 0, 0, 0.1);
}

.elevation-3 {
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.1));
}

/* from: https://stackoverflow.com/a/70806881 */
.hidden-if-empty:empty {
  display: none;
}
